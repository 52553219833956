<template>
  <div id="scenario-form-page">
    <section class="create-room">
      <div class="container">
        <h2 class="detail-title mb24">{{ title_page }}</h2>
        <form @submit.prevent="submit">
          <div class="box-create">
            <div class="row">
              <div class="col-lg-6 col-sm-6">
                <div class="create-form">
                  <label class="label-form">Title</label>
                  <input type="text" name="title" id="title_agenda" class="form-control" placeholder="Title" v-model="form.title" :class="{ 'is-invalid': $v.form.title.$error }">
                  <div v-if="$v.form.title.$error" class="invalid-feedback">
                    <span v-if="!$v.form.title.$required">This value is required.</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6">
                <div class="create-form">
                  <label class="label-form">Case</label>
                  <select class="form-select" name="room" id="room_agenda" v-model="form.room" :class="{ 'is-invalid': $v.form.room.$error, black: form.room !== null }">
                    <option :value="null">Case</option>
                    <option v-for="(room, index) in rooms" :key="index" :value="room.id">{{ room.subject_topic }}</option>
                  </select>
                  <div v-if="$v.form.room.$error" class="invalid-feedback">
                    <span v-if="!$v.form.room.required">This value is required.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-lg-6">
                <div class="create-form" :class="[$v.form.date.$error ? 'date-error' : 'date']">
                  <label class="label-form" :class="{ 'is-invalid': $v.form.date.$error }">Date</label>
                  <date-picker v-model="form.date" format="DD-MM-YYYY" :clearable="false" placeholder="Date" inputClass="form-control datepicker fordate">
                    <i slot="icon-calendar"></i>
                  </date-picker>
                  <div v-if="$v.form.date.$error" class="invalid-feedback">
                    <span v-if="!$v.form.date.required">This value is required.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-lg-12">
                <div class="box-upload">
                  <label class="label-form">Upload Banner</label>
                  <div class="drop-zone" @click="$refs.inputBanner.click()">
                    <span class="drop-zone__prompt" v-if="form.upload.isEmpty">
                      <img src="/img/icn-upload.svg" class="img-fluid"><br>
                      Click this button to upload file
                    </span>
                    <div class="drop-zone__thumb" :data-label="form.upload.filename" v-if="!form.upload.isEmpty">
                      <img :src="form.upload.fileUrl" class="img-fluid"><br>
                    </div>
                    <input type="file" id="input-banner" ref="inputBanner" name="myFile" accept=".jpg, .jpeg, .png, .gif" class="drop-zone__input" @change="preview">
                  </div>
                  <div class="d-flex align-items-center justify-content-between mt30">
                    <div class="placeholder">&nbsp;</div>
                    <div class="img-requirment">
                      *Allowed JPG, JPEG, GIF or PNG. Max size of 4 MB
                    </div>
                    <div class="for-btn-reset">
                      <button type="button" class="btn btn-delete w-100" :disabled="form.upload.isEmpty" @click="clearBanner">Reset</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-lg-12">
                <div class="create-form">
                  <label class="label-form" :class="{ 'is-invalid': $v.form.description.$error }">Description</label>
                  <vue-editor v-model="form.description" placeholder="Description"></vue-editor>
                  <div v-if="$v.form.description.$error" class="invalid-feedback">
                    <span v-if="!$v.form.description.required">This value is required.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="for-btn-save">
            <div class="btn-save-box">
              <button type="submit" class="btn btn-orange w-100">Save Changes</button>
            </div>
            <div class="btn-delete-box">
              <router-link to="/scenario/manage/list" class="btn btn-delete w-100">Cancel</router-link>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import {
  required
} from "vuelidate/lib/validators";
import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";

export default {
  name: "ScenarioForm",
  components: { VueEditor },
  data() {
    return {
      rooms: [],
      form: {
        title: null,
        room: null,
        date: null,
        banner: null,
        description: null,
        upload: {
          isEmpty: true,
          filename: null,
          file: null,
          fileUrl: null
        }
      }
    }
  },
  validations: {
    form: {
      title: { required },
      date: { required },
      room: { required },
      description: { required },
    }
  },
  computed: {
    title_page() {
      if (this.$route.name == 'ScenarioEdit') {
        return 'Edit Crisis Scenario'
      } else {
        return 'Add Crisis Scenario'
      }
    }
  },
  mounted() {
    this.fetchRooms();
    if (this.$route.name == 'ScenarioEdit') {
      this.getScenarioDetail() 
    }
  },
  methods: {
    getScenarioDetail() {
      this.$axios.get(`/master/crisis_scenario/${this.$route.params.id}`)
        .then(response => {
          console.log(response.data.data)
          const result = response.data.data
          this.form.title = result.title
          this.form.room = result.room_id
          this.form.date = new Date(this.formatDate(result.date))
          this.form.description = result.description
          if (result.banner) {
            this.form.upload = {
              isEmpty: false,
              filename: result.banner,
              file: null,
              fileUrl: result.banner_url
            }
          }
        })
    },
    fetchRooms() {
      this.$axios.get('/room_list')
        .then(response => {
          console.log(response.data.data)
          this.rooms = response.data.data
        })
    },
    preview(event) {
      if(event.target.files[0] !== undefined){
        const extension = event.target.files[0].name.split(".").pop()
        const allowedExtention = ['jpg','jpeg','png','gif']
        const fileSize = Math.round((event.target.files[0].size / 1024)); 
        if(allowedExtention.includes(extension.toLowerCase()) && fileSize <= 4096){
          this.form.upload.isEmpty = false
          this.form.upload.filename = event.target.files[0].name
          this.form.upload.file = event.target.files[0]
          this.form.upload.fileUrl = URL.createObjectURL(event.target.files[0]);
        }
      }
    },
    clearBanner() {
      this.form.upload.isEmpty = true
      this.form.upload.filename = null
      this.form.upload.file = null
      this.form.upload.fileUrl = null
      document.getElementById('input-banner').value = null
    },
    async uploadBanner() {
      let param = new FormData();
      param.append('file', this.form.upload.file);
      param.append('folder', 'crisis');
      param.append('quality', '80');

      await this.$axios.post('/upload_image', param)
        .then(response => {
          this.form.banner =response.data.filename
        })
    },
    async submit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        // loading overlay
        const loader = this.$loading.show({
          container: null,
          lockScroll: true,
          opacity: 1
        });
        // upload banner 
        if (this.form.upload.file) {
          await this.uploadBanner();
        }
        // submit scenario data
        let submit = null
        // submit room data
        if (this.$route.name == 'ScenarioEdit') {
          submit = this.$axios.patch('/master/crisis_scenario', {
            id: this.$route.params.id,
            title: this.form.title,
            room_id: this.form.room,
            date: this.formatDate(this.form.date),
            banner: this.form.upload.file ? this.form.banner : null,
            description: this.form.description
          })
        } else {
          submit = this.$axios.post('/master/crisis_scenario', {
            title: this.form.title,
            room_id: this.form.room,
            date: this.formatDate(this.form.date),
            banner: this.form.upload.file ? this.form.banner : null,
            description: this.form.description
          })
        }

        submit.then(response => {
            console.log(response);
            loader.hide();
            Swal.fire("Success", response.data.message, "success");
            this.$router.push('/scenario/manage/list')
          }).catch(error => {
            loader.hide();
            console.log(error);
          })
      }
    }
  }
}
</script>


<style lang="css" scoped>
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.mx-datepicker {
  width: 100% !important;
}
.mx-icon-calendar {
  display: none !important;
}
.create-form.date-error::after{
  font-family: 'icomoon';
  content: "\e906";
  font-size: 20px;
  position: absolute;
  right: 15px;
  bottom: 40px;
  color: #434343;
}
.black {
  color: black !important;
}
</style>